<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- Hero and Heading -->
        <v-container>
            <v-row justify="center" class="mb-15">
                <v-col cols="auto" align="center">
                    <h1><heading>求人情報</heading></h1>
                </v-col>
                <v-col cols="12" md="12" align="center">
                    <v-img :src="imageHero" :key="imageHero"></v-img>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col
                    :cols="smAndUp ? 9 : 'auto'"
                    :align="smAndUp ? 'center' : 'left'"
                >
                    <div
                        class="message font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        お客様起点で学習を重ね、REVOISTのサービスを健康インフラに育てていくことに共に挑戦していける、自立的、主体的なパーソナルトレーナーを募集しています。
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="center">
                    <v-row>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#our-service')"
                            >
                                <span
                                    class="link-text font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >サービスの特徴
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#trainer-features')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >トレーナーの特徴
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#future-career')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >将来のキャリアの特徴
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="
                                    $vuetify.goTo('#training-support-system')
                                "
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >研修サポート制度
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#trainer-voice')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >トレーナーの声
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#faq')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >よくある質問
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#selection-flow')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >選考フロー
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#recruit-conditions')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >採用募集条件
                                </span>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                text
                                tile
                                class="link-tag mt-6 py-auto"
                                @click="$vuetify.goTo('#apply')"
                            >
                                <span
                                    class="link-text text-h6 font-weight-light black--text"
                                    :class="smAndUp ? 'text-h6' : 'text-body-1'"
                                    >申込はこちら
                                </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- サービスの特徴 -->
            <v-row justify="center" class="mt-10 mb-4">
                <v-col
                    id="our-service"
                    cols="auto"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >サービスの特徴</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">パ</span
                        >ーソナルトレーニング業界の課題
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>「今のカラダを変えたい。」</p>
                        <p>こんな悩みをもった運動初心者の方の多くは、</p>
                        <ul>
                            <li>正しいトレーニング方法がわからない</li>
                            <li>効果が出ない</li>
                        </ul>
                        <p>という悩みを抱えています。</p>
                        <p>
                            実は、パーソナルトレーナーのサポートを通じて、レベルと目的に合った正しいトレーニングと食事の習慣を手にできれば、人は誰でも、今より美しく、カッコよく、健康的に、カラダを変えることができます。
                        </p>
                        <p>
                            しかし、世の中の一般の方にとって、パーソナルトレーニングは、
                        </p>
                        <ul>
                            <li>敷居の高いサービス</li>
                            <li>続けにくいサービス</li>
                        </ul>
                        <p>となってしまっているのが現状です。</p>
                        <p>それは、</p>
                        <ul>
                            <li>1回10,000円前後の価格設定</li>
                            <li>糖質制限で身体に負担の掛かるダイエット</li>
                            <li>高額なサプリメント販売</li>
                        </ul>
                        <p>
                            という「高額×短期間」のパーソナルジムが業界のスタンダードになっているためです。
                        </p>
                        <p>
                            事実、せっかくお客様のカラダが変わったとしても、パーソナルトレーニングを継続することが、仕組み上、難しく、結果的にリバウンドをしてしまうケースも多いです。
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">パ</span
                        >ーソナルトレーニングを受けやすく、続けやすく
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>
                            そのような背景を踏まえ、REVOISTのパーソナルトレーニングは、
                        </p>
                        <ul>
                            <li>1回5,000円</li>
                            <li>週1回50分</li>
                            <li>お客様の家から近い立地</li>
                        </ul>
                        <p>
                            というモデルで展開しており、
                            パーソナルトレーニングに
                        </p>
                        <ul>
                            <li>興味がある方が受けやすい</li>
                            <li>続けたいお客様が続けやすい</li>
                        </ul>
                        <p>そんなサービスの仕組みにしています。</p>
                    </div>
                </v-col>
            </v-row>
            <!-- REVOISTが目指す世界観 -->
            <v-row class="mt-10">
                <v-col
                    id="view-of-the-world"
                    cols="12"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >REVOISTが目指す世界観</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="center">
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        人の心身の健康を実現するうえで最も効果的な手段「パーソナルトレーニングサービス」
                        を、一人でも多くの人の生活習慣に取り込んでいただき、健康インフラになることを目指しています。
                    </div>
                </v-col>
            </v-row>

            <!-- トレーナーの特徴 -->
            <v-row class="mt-10">
                <v-col
                    id="trainer-features"
                    cols="12"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >トレーナーの特徴</v-col
                >
            </v-row>

            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">お</span>客様にとっての必需品
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        価格設定がリーズナブルで、無理のない頻度で、家から近い立地でも、運動初心者のお客様
                        がトレーニングを続けるのは容易なことではありません。
                        スマホや電気、電車などといった人生の必需品（インフラ）と同じように、
                        パーソナルトレーニングを人生の必需品としてお客様が長期で考えてくれるかどうかは、
                        我々、パーソナルトレーナーにかかっています。
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">R</span>EVOISTコンサルタント
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>REVOISTのトレーナーは、</p>
                        <p>
                            ダイエットやボディメイクといったお客様の悩みを解決することに加え、心身の健康も実現し、その先に、お客様の人生をより前向きにしていきたい。
                        </p>
                        <p>そんな熱い想いを持ちながら、</p>
                        <p>お客様の</p>
                        <ul>
                            <li>美容の課題</li>
                            <li>健康の課題</li>
                            <li>人生の課題</li>
                        </ul>
                        <p>
                            を解決し続け、お客様の前向きな人生を支援するための長期的な伴走を大切にしています。
                        </p>
                        <p>
                            そのためREVOISTでは、目指すトレーナー像を「お客様の人生の課題解決を支援するコンサルタント」と定義しています。
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">お</span>客様起点の学習
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>
                            REVOISTの全員が大切にしている合い言葉は、お客様起点です。
                        </p>
                        <p>
                            お客様とのコミュニケーションを通じて、地域のこと、ご家族のこと、お仕事のこと、趣味のことなど、お客様の生活背景を理解し、共感した上で、一人ひとりのお客様に合った楽しいトレーニング習慣を提供しています。
                        </p>
                        <p>そのためトレーナーは</p>
                        <ul>
                            <li>お客様に寄り添ったコミュニケーションの学習</li>
                            <li>トレーナーとしての知識と技能の学習</li>
                            <li>課題解決などのビジネススキルの学習</li>
                        </ul>
                        <p>
                            を常に心がけ、トレーナーとして、人として、お客様起点で日々成長し続けています。
                        </p>
                    </div>
                </v-col>
            </v-row>

            <!-- 将来のキャリアの特徴 -->
            <v-row class="mt-10">
                <v-col
                    id="future-career"
                    cols="12"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >将来のキャリアの特徴</v-col
                >
            </v-row>

            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">ト</span
                        >レーナーとしての将来の不安
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        トレーナーの仕事は、やりがいがあって、自分に合っていて、大好きで、続けたい。
                        だけど、年齢を重ねてトレーナーだけで現場にずっと立ち続けるわけにはいかないし、将来が不安。世の中の多くのトレーナーがきっと感じている不安と考えています。
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">全</span>員経営参加型の組織
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>
                            REVOISTの特徴は、トレーナー全員経営参加型の組織であることです。
                            トレーナーとして任された店舗（エリア）の発展はもちろんのこと、組織経営の役割（プロジェクト）を担い、推進していくことを期待しています。
                            具体的な組織経営の役割として、
                        </p>
                        <ul>
                            <li>新人育成</li>
                            <li>トレーナー採用</li>
                            <li>広報活動</li>
                            <li>店舗運営</li>
                            <li>マネジメント</li>
                            <li>トレーナースキル開発</li>
                        </ul>
                        <p>
                            などのプロジェクトが用意されており、トレーナー全員がそれぞれを担当することで経営を味わい、ビジネススキル習得の機会としています。
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">ビ</span>ジネススキルの学習
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>
                            組織経営の役割をチームで協働しながら推進していくためには、
                        </p>
                        <ul>
                            <li>マネジメント</li>
                            <li>マーケティング</li>
                            <li>リーダーシップ</li>
                        </ul>
                        <p>などたくさんのビジネススキルが求められます。</p>
                        <p>
                            REVOISTでは、トレーナー一人ひとりの性格や持ち味を理解し、プロジェクトを任せることで、強み弱みを補完しあったチームを組成しており、仲間と協働していく過程でビジネスマンとして成長,活躍していくことができます。
                        </p>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h4 mb-4 ' : 'text-h6'"
                        class="font-weight-thin"
                    >
                        <span class="red--text">目</span>指す組織像
                    </div>
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        REVOISTは、トレーナースキル×ビジネススキルを兼ね備えたコンサルタント集団として、
                        ヒト（トレーナー）で勝つ経営をしながら、パーソナルジム業界のリーダーになることを目
                        指しています。
                        そして、そんなパーソナルトレーナーの働き方が業界のスタンダードになることを目指して
                        います。
                    </div>
                </v-col>
            </v-row>

            <!-- 研修サポート制度 -->
            <v-row class="mt-10">
                <v-col
                    id="training-support-system"
                    cols="12"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >研修サポート制度</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <div
                        :class="
                            smAndUp
                                ? 'red-border text-body-1'
                                : 'red-border text-body-1'
                        "
                        class="font-weight-light"
                    >
                        <ul>
                            <li>新人育成カリキュラム（入社後研修）</li>
                            <li>行動評価会（自立支援）</li>
                            <li>近況報告会（コンディションフォロー）</li>
                            <li>プロジェクト制度（ビジネススキル開発）</li>
                            <li>社内報（全社理解支援）</li>
                            <li>キックオフミーティング（全社総会）</li>
                            <li>交流会（懇親会,勉強会など）</li>
                        </ul>
                    </div>
                </v-col>
            </v-row>

            <!-- 経営理念 -->
            <v-row>
                <v-col
                    id="management-philosophy"
                    cols="12"
                    align="center"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >経営理念</v-col
                >
            </v-row>

            <!-- ビジョン -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                >
                    <span class="red--text">ビ</span
                    >ジョン（目指したい未来）</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        class="message font-weight-light my-0"
                        :class="smAndUp ? 'text-h4' : 'text-h6'"
                    >
                        ひとりでも多くの人たちが心と身体の健康を実現し、
                        前向きに人生を歩めている社会を創りたい。
                    </div>
                </v-col>
            </v-row>
            <!-- ミッション -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                >
                    <span class="red--text">ミ</span
                    >ッション（社会的使命）</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        class="message font-weight-light my-0"
                        :class="
                            smAndUp
                                ? 'text-h4 font-weight-light'
                                : 'text-h5 font-weight-light'
                        "
                    >
                        お客様ひとりひとりの 価値観に沿った健康的で 楽しい
                        生活習慣を共に創り続けます。 その実現に向けて、
                        トレーナーは日々学習、協働を、 経営は身近で快適で
                        通いやすい空間を、提供し続けます。
                    </div>
                </v-col>
            </v-row>

            <!-- サービスポリシー（価値基準） -->
            <v-row justify="center">
                <v-col
                    cols="auto"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                >
                    <span class="red--text">サ</span
                    >ービスポリシー（価値基準）</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        class="message font-weight-light my-0"
                        :class="
                            smAndUp
                                ? 'text-h4 font-weight-light'
                                : 'text-h5 font-weight-light'
                        "
                    >
                        深い相互理解を通じて、信頼関係を構築し、お客様ひとりひとりに通いがいを感じてもらえるサービスを提供する。
                    </div>
                </v-col>
            </v-row>

            <!-- トレーナーの声 -->
            <v-row>
                <v-col
                    id="trainer-voice"
                    cols="12"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >トレーナーの声</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="8">
                    <trainer-introduction></trainer-introduction>
                </v-col>
            </v-row>

            <!-- よくあるご質問 -->
            <v-row>
                <v-col
                    id="faq"
                    cols="12"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >よくあるご質問</v-col
                >
            </v-row>

            <v-row>
                <v-col offset-md="1" md="10" cols="12">
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            v-for="qa in qaList"
                            :key="qa.q"
                        >
                            <qa :question="qa.q" :answer="qa.a"></qa>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- 選考フロー -->
            <v-row>
                <v-col
                    id="selection-flow"
                    cols="12"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >選考フロー</v-col
                >
            </v-row>

            <v-row>
                <v-col
                    class="blue-grey lighten-5"
                    offset-md="4"
                    cols="12"
                    md="4"
                    align="center"
                >
                    <div>
                        <p class="my-4 text-h5 font-weight-thin">
                            書類審査
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            ↓
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            1次面接（採用担当）
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            ↓
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            2次面接（経営本部）
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            ↓
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            最終面接（代表）
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            ↓
                        </p>
                        <p class="my-4 text-h5 font-weight-thin">
                            採用
                        </p>
                    </div>
                </v-col>
            </v-row>

            <!-- 採用募集条件 -->
            <v-row>
                <v-col
                    id="recruit-conditions"
                    cols="12"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >採用募集条件</v-col
                >
            </v-row>

            <v-row justify="center">
                <v-col :cols="smAndUp ? 9 : 'auto'" align="left">
                    <div
                        :class="smAndUp ? 'text-h6' : 'text-body-1'"
                        class="font-weight-light"
                    >
                        <p>
                            仲間になってくださるトレーナーのかたには、そういった機会を活かし、自身の人間力を自らアップデートし続けていくことが期待されます。
                        </p>
                        <p>そのため</p>
                        <ul>
                            <li>お客様起点で柔軟に謙虚に動ける能動的なかた</li>
                            <li>
                                パーソナルトレーナーという仕事に誇りを持ち更なる成長を目指しているかた
                            </li>
                            <li>
                                チーム協働でお客様の期待に応えていくことを楽しめるかた
                            </li>
                            <li>トレーナー経験を1年以上お持ちのかた</li>
                        </ul>
                        <p>からの応募をお待ちしています。</p>
                        <p>
                            パーソナルトレーニング業界は世の中的な認知は高まってきているものの、まだまだ利用したことが無いかたも多く、サービスの在り方にも改善余地を残している業界です。
                        </p>
                        <p>
                            そんな伸びしろのあるマーケットで、新しいパーソナルトレーニングの在り方を発信し続けているREVOISTは、いつの日か必ず掲げたビジョンを成し遂げます。
                        </p>
                        <p>
                            そんなジムを私たちと一緒に創っていきませんか？お会いできるのを楽しみにしています。
                        </p>
                    </div>
                </v-col>
            </v-row>

            <!-- 申込はコチラ -->
            <v-row>
                <v-col
                    id="apply"
                    cols="12"
                    align="center"
                    class="mt-6"
                    :class="
                        smAndUp
                            ? 'text-h4 font-weight-light'
                            : 'text-h5 font-weight-light'
                    "
                    >申込はコチラ</v-col
                >
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 6 : 8" align="left">
                    <v-btn
                        x-large
                        block
                        class="apply-button white--text"
                        color="#E25959"
                        href="https://en-gage.net/revoist/"
                        target="_blank"
                    >
                        エンゲージから申し込む
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <!-- <v-col :cols="smAndUp ? 6 : 8" align="left">
                    <v-btn
                        x-large
                        block
                        class="apply-button white--text"
                        color="#E25959"
                    >
                        インディードで申し込む
                    </v-btn>
                </v-col> -->
            </v-row>
            <v-row justify="center">
                <v-col :cols="smAndUp ? 6 : 8" align="left">
                    <!-- Navigate to https://lin.ee/WpjTt78 -->
                    <v-btn
                        x-large
                        block
                        class="apply-button white--text"
                        color="#E25959"
                        href="https://lin.ee/WpjTt78"
                        target="_blank"
                    >
                        LINEで申し込む
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import FooterContents from '@/components/FooterContents.vue'
import Heading from '@/components/Heading.vue'
import TrainerIntroduction from '@/components/recruit/TrainerIntroduction.vue'
import Qa from '@/components/faq/Qa.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                'content',
                `お客様起点で学習を重ね、REVOISTのサービスを健康インフラに育てていくことに共に挑戦していける、自立的、主体的なパーソナルトレーナーを募集しています。`
            )
    },
    components: {
        FooterContents,
        Heading,
        TrainerIntroduction,
        Qa,
    },
    data() {
        return {}
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '求人情報',
                    disabled: false,
                    href: 'recruit',
                },
            ]
        },
        qaList() {
            return [
                {
                    q:
                        "<span><span class='q-color'>Q1</span>" +
                        '：在籍トレーナーの男女比は？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：男性90% 女性10%です！</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q2</span>" +
                        '：在籍トレーナーの年齢層は？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：25歳～35歳が一番多く、最年長は1982年生まれです。</span>',
                },
                {
                    q:
                        "<span><span class='q-color'>Q3</span>" +
                        '：仕事においてどんな価値観をもった人が多いですか？</span>',
                    a:
                        '<span><span class="a-color">A</span>' +
                        '：第1位 社会貢献 第2位 ワークライフバランス 第3位 管理能力</span>',
                },
            ]
        },
        imageHero() {
            const path = this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/recruit/hero_pc.png'
                : 'static/landing/trainer/recruit/hero_sp.png'
            return path
        },
    },
}
</script>

<style lang="scss" scoped>
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    /* ul 要素のあとには行間を空ける */
    + * {
        margin-top: 0.5em;
    }
}
li {
    padding-left: 10px; /* ここでliタグ全体に対するインデントを設定 */
}
li::before {
    content: '・';
    padding-right: 10px; /* この値を調整することで中黒の右側のスペースを制御 */
    padding-left: 10px; /* この値を調整することで中黒の左側のスペースを制御 */
}

.q-color {
    color: #1a0dab;
}
.a-color {
    color: #c30013;
}

.message {
    line-height: 1.5em;
}
.table-border {
    border: 1px solid white;
}
.business-summary {
    > .key {
        border-bottom: 1px solid #c30013;
    }
    .value {
        border-bottom: 1px solid gray;
    }
}
.history-key {
    display: flex;
    align-items: center;
    text-align: center;

    &::after {
        content: '';
        height: 1px;
        background-color: #c30013;
        flex-grow: 1;
        margin-left: 20px;
        padding-right: 10px;
    }
}

.link-tag {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed;
    border-width: 0 0 1px 0;
    border-color: #666666;
}
.link-text {
    position: relative; /* 疑似要素の位置基準 */
}

.link-text:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f107';
    font-weight: 900;
    color: #c30013;

    position: absolute; /* 絶対位置指定 */
    left: -15px; /* 要素の左側に配置 */
    top: 50%; /* 要素の高さに対して中央に配置 */
    transform: translateY(-50%); /* Y軸方向に調整 */
}

.red-border {
    padding: 10px;
    margin: 10px 0;
    border: 6px solid #e25959;
    border-radius: 6px;
    opacity: 1;
}

.apply-button {
    position: relative; /* 擬似要素の位置を制御するために relative を設定 */
}

.apply-button::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    color: white;

    position: absolute; /* 絶対位置指定 */
    right: 15px; /* 要素の左側に配置 */
    top: 50%; /* 要素の高さに対して中央に配置 */
    transform: translateY(-50%); /* Y軸方向に調整 */

    background-color: transparent; /* 背景を透明に */
    box-shadow: none; /* ボックスシャドウを削除 */
    border: none; /* ボーダーを削除 */
}
</style>
