<template>
    <!-- トレーナー紹介文 -->
    <v-row>
        <v-col sm="12" md="12">
            <v-row>
                <v-col
                    cols="auto"
                    align="left"
                    class="font-weight-light"
                    :class="smAndUp ? 'text-h5' : 'text-h6'"
                >
                    <span class="red--text">採</span
                    >用担当<br />大西梢哉トレーナー</v-col
                >
            </v-row>

            <v-row>
                <v-col
                    sm="12"
                    md="6"
                    class="d-flex justify-center align-center"
                >
                    <v-img
                        :src="trainerMrOnishi"
                        :key="trainerMrOnishi"
                        contain
                    ></v-img>
                </v-col>
                <v-col
                    sm="12"
                    md="6"
                    :class="smAndUp ? 'd-flex align-center' : ''"
                    class="font-weight-light"
                >
                    <div v-html="messageOnishi"></div>
                </v-col>
            </v-row>
        </v-col>
        <v-col sm="12" md="12">
            <v-row>
                <v-col
                    cols="auto"
                    align="left"
                    class="font-weight-light"
                    :class="smAndUp ? 'text-h5' : 'text-h6'"
                >
                    <span class="red--text">新</span
                    >人研修担当<br />箕輪匠悟トレーナー</v-col
                >
            </v-row>

            <v-row>
                <v-col
                    sm="12"
                    md="6"
                    class="d-flex justify-center align-center"
                >
                    <v-img
                        :src="trainerMrMinowa"
                        :key="trainerMrMinowa"
                        contain
                    ></v-img>
                </v-col>
                <v-col
                    sm="12"
                    md="6"
                    :class="smAndUp ? 'd-flex align-center' : ''"
                    class="font-weight-light"
                >
                    <div v-html="messageMinowa"></div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        messageOnishi: {
            required: true,
            type: String,
            default: () =>
                `<p>ご覧いただき、ありがとうございます！
採用担当の大西梢哉と申します！</p>
<p>私自身、パーソナルトレーナーという職業は好きであるものの、自分の考えと合った企業が見つからずにモヤモヤし、将来の不安を抱えたまま働いていた時期がありました。
そんな時にREVOISTと出会い、今では自分の将来像に向けて活き活きと活動することができています！</p>
<p>それはREVOISTという会社が『真のお客様起点』を大事にしているため、自分も自信を持ってサービスを提供できていることや、自分の考えや想いを尊重してくれる環境だからです！</p>
<p>このページをご覧になられている方の中には、過去の僕と同じように向上心はあるのに、100%の力を注げていない方がいると思います！
ぜひ一度、ご連絡ください！</p>
`,
        },
        messageMinowa: {
            required: true,
            type: String,
            default: () =>
                `<p>初めまして！
新人研修担当の箕輪匠悟と申します。</p>
<p>まず初めに、REVOISTは「社会貢献」という大きなビジョンを掲げ、健康産業におけるパーソナルトレーニングのインフラ化(必要不可欠なもの)の実現に向けて、同じ価値観を持って仲間同士で協働している組織です。</p>
<p>経営では「三方良し」にならい、社会・お客様・働く仲間に対して、それぞれに良い影響を提供し続けることを目的としています。
売上ノルマや無理な営業などもなく、お客様にも働く仲間にもクリーンな経営が魅力の一つとなります。</p>
<p>お客様により良いサービスをご提供していくためには、我々トレーナーが良い状態であることが大切です。</p>
<p>REVOISTでは全トレーナーが個人事業主という働き方で、1人1人の強みを尊重したキャリアパスがあり、トレーナー自身も経営に参加することで働く選択肢の増加＝働きがいにも繋がります。</p>
<p>加入してくださるトレーナーの安心・活躍を目指して、私たちが寄り添いながら成長をサポートいたします！</p>
`,
        },
        shopImage: {
            required: true,
            type: String,
            default: () => 'static/landing/pc/IMG_9986@2x.png',
        },
        trainerMrOnishi: {
            required: true,
            type: String,
            default: () => 'static/pc_recruit/1702029524097.jpg',
        },
        trainerMrMinowa: {
            required: true,
            type: String,
            default: () => 'static/pc_recruit/1702029508663.jpg',
        },
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped></style>
